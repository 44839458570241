import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { getProductBanners } from "../Firebase.js";
import BannerSliderShimmer from "./BannerSliderShimmer.js";
import "../css/ProjectShare.css";
import "../css/slick.css";
import "../css/slick-theme.css";


function SimpleSlider(props) {
  const [sliderArray, setSliderArray] = useState([]);

  useEffect(() => {
    getProductBanners((images) => {
      setSliderArray(images);
    });
  },[]);

  const urlArray = sliderArray.filter((item) => item !== null)
    .map((item) => Object.entries(item)[0]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  if (sliderArray.length === 0) {
    return (
      <>
        <BannerSliderShimmer />
      </>
    );
  } else {
    return (
      <>
        <Slider {...settings}>
          {urlArray.map((entry) => {
            if (entry[0].includes("AllProjects")) {
              return (
                <Link
                  key={entry[0]}
                  to={{
                    pathname: "/Projects",
                    search: "?category="+entry[0].split("_")[1]+"&dist="+props.dUid,
                  }}
                  className="link-Slider"
                >
                  <img src={entry[1]} alt="" />
                </Link>
              );
            } else if (entry[0].includes("ProjectMain")) {
              return (
                <Link
                  key={entry[0]}
                  to={{
                    pathname: "/ProjectShare",
                    search: "?category="+entry[0].split("_")[1]+"&projectName="+entry[0].split("_")[2]+"&dist="+props.dUid,
                  }}
                  className="link-Slider"
                >
                  <img src={entry[1]} alt="" />
                </Link>
              );
            } else {
              return <img key={entry[0]} src={entry[1]} alt=""/>;
            }
          })}
        </Slider>
      </>
    );
  }
}

export default SimpleSlider;
