import { CSVLink } from "react-csv";
import download from '../images/download_icon.png';

function formatDate(date) {
  if (!date) return "";
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
}
function ExportLeads({allLeads}) {

  const headers = [
    { label: "LeadId", key: "id" },
    { label: "CustomerName", key: "name" },
    { label: "CustomerMobile", key: "mobile" },
    { label: "DateOfSubmission", key: "dateOfSubmission" },
    { label: "Category", key: "leadCat" },
    { label: "Product", key: "projName" },
    { label: "PayoutState", key: "payoutState" },
    { label: "Remark", key: "remarks" },
  ];

  const csvLink = {
    filename: "leads.csv",
    headers: headers,
    data: allLeads.map((lead) => ({
      id: lead.id,
      name: lead.name,
      mobile: lead.mobile,
      dateOfSubmission: formatDate(lead.dateOfSub),
      leadCat: lead.leadCat,
      projName: lead.projName,
      payoutState: lead.payoutState,
      remarks: lead.remarks,
    })),
  };

  return (
    <div>
    <CSVLink {...csvLink}>
    <img src={download} alt="download_lead" id="download_lead_img" />
    <p  id="download_lead_link">Download Leads</p>
    </CSVLink>
  </div>
  )
}

export default ExportLeads;
