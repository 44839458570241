
export const WAITING="waiting";
export const ONHOLD="onHold";
export const INIT="init";
export const SUCCESS="success";
export const EXISTS="exist";
export const SUBMITTED="submitted";
export const FAILURE="failure";
export const ALLCC="ALLCC";
export const INVALIDURL="InvalidUrl";
export const ERROR="Error";
export const PROCEED="proceed";
export const INVALID_PINCODE="Invalid Pincode";
export const VALID = "Valid";
export const INVALID = "InValid";
export const LOGIN_INIT = "LoginInit";
export const LOGEDIN = "LogedIn";
export const LOGOUT = "Logout";
export const VERIFYING_OTP = 'VerifyingOtp';
export const NEW_USER = 'NewUser';
export const SWITCHED = 'Switched';
