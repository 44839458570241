import * as Days from './Constants';


export function storeAgent(uid, agent) {
  localStorage.setItem(
    "agent",
    JSON.stringify({
      uid: uid,
      name: agent.name,
      mobile: agent.mobile,
      email: agent.email,
      pincode: agent.pincode,
    })
  );
}

export function getStoredAgent() {
  return JSON.parse(localStorage.getItem("agent"));
}

export function clearStoredAgent() {
  localStorage.removeItem("agent");
}
export function storeOwner(uid, owner) {
  localStorage.setItem(
    "owner",
    JSON.stringify({ uid: uid, name: owner.name, mobile: owner.mobile })
  );
}

export function getStoredOwner() {
  return JSON.parse(localStorage.getItem("owner"));
}

export const selectedFilterItems = [
  "selectedProjects",
  "selectedLeadStatus",
  "selectedPaymentStatus",
  "startDate",
  "endDate",
  "selectedRange" ,
  "selectedStartDate" ,
  "selectedEndDate" ,
  "allSelects" ,
  "dateSelectionStatus"
];

export function isSelectedFilterNotEmpty() {
  const keysExistInLocalStorage = selectedFilterItems.some((key) => localStorage.getItem(key) !== null);
  return keysExistInLocalStorage;

}


export function removeStoredFilter() {
  selectedFilterItems.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

const valuesToClear = [Days.SEVEN_DAYS, Days.THIRTY_DAYS, Days.MORE_FILTER];



export function removeStoredValue() {
  valuesToClear.forEach((key) => {
    localStorage.removeItem(key);
  });
}
