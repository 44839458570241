import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { importAll } from "./Helper";
import { getLiveProjects, getOnHoldProjects } from "../Firebase";
import AllProjectsShimmer from "./AllProjectsShimmer";
import { getStoredAgent } from "./LocalStorage";
import "../css/AllProjects.css";

export default function AllProjects() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const dUid = searchParams.get("dist");
  const agent = getStoredAgent();

  var [listProj, setListProj] = useState([]);
  var [listOnHold, setListOnHold] = useState([]);
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  const navigation = useNavigate();
  const [isLiveProjectLoaded, setIsLiveProjectLoaded] = useState(false);
  const [isHoldProjectLoaded, setIsHoldProjectLoaded] = useState(false);



  useEffect(() => {
    if (agent === null) {
      window.localStorage.clear();
      navigation({
        pathname: "/",
        search: `?dist=${dUid}`,
        logout: true,
      });
    } 

    getLiveProjects(category, (list) => {
      if (list !== null) {
        setListProj(list);
      }
      setIsLiveProjectLoaded(true);
    });

    getOnHoldProjects(category, (list) => {
      if (list !== null) {
        setListOnHold(list);
      }
     
      setIsHoldProjectLoaded(true);
    });
  }, []);

  if (isLiveProjectLoaded && isHoldProjectLoaded) {
    return (
      <div className="main-container">
       <div className="linkPath">
          <div className="navbar-ps">
            <div className="category-ps">
              <p id="cat-name">{category}</p>
            </div>
          </div>
        </div>

        <div className="listProjects">
          {listProj.map((project, index) => (
            <Link
              key={index}
              to={{
                pathname: "/ProjectShare",
                search: `?category=${category}&projectName=${project.name}&dist=${dUid}`,
              }}
              className="linkPath"
            >
              <div className="mainbox-page">
                <div className="active-label">Active</div>
                <div className="product-container">
                  <div className="product-inner">
                    <div className="project-logo">
                      <img src={project.logo} alt="" className="logo" />
                    </div>
                    <div className="project-heading">{project.heading}</div>
                  </div>
                  <div className="usp-box">
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={images["tag.png"]} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp1}</p>
                      </div>
                    </div>
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={images["tag.png"]} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp2}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`goal-container ${
                      project.goal2 && project.payout2 ? "visible" : "hidden"
                    }`}
                  >
                    <h6 className="goal">Goals</h6>
                    <div className="goal-box">
                      <div className="goal-box-inner">
                        <div className="goal-image">
                          <img src={images["aryoright.png"]} alt="" />
                        </div>
                        <div className="goal-info">
                          <p>{project.goal1}</p>
                        </div>
                      </div>
                    </div>
                    <div className="goal-box">
                      {project.goal2 && (
                        <div className="goal-box">
                          <div className="goal-box-inner">
                            <div className="goal-image">
                              <img src={images["aryoright.png"]} alt="" />
                            </div>
                            <div className="goal-info">
                              <p>{project.goal2}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div className="listHoldProjects">
          {listOnHold.map((project , index) => (
            <div key={index}>
              <div className="mainbox-page" style={{paddingBottom : '8px'}}>
                <div className="hold-label">Hold</div>
                <div className="product-container">
                  <div className="product-inner">
                    <div className="project-logo">
                      <img src={project.logo} alt="" className="logo" />
                    </div>
                    <div className="project-heading">{project.heading}</div>
                  </div>
                  <div className="usp-box">
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={images["tag.png"]} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp2}</p>
                      </div>
                    </div>
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={images["tag.png"]} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp2}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`goal-container-hold ${
                    project.goal2 && project.payout2 ? "visible" : "hidden"
                  }`}
                >
                  <h6 className="goal">Goals</h6>
                  <div className="goal-box">
                    <div className="goal-box-inner">
                      <div className="goal-image">
                        <img src={images["aryoright.png"]} alt="" />
                      </div>
                      <div className="goal-info">
                        <p>{project.goal1}</p>
                      </div>
                    </div>
                  </div>
                  <div className="goal-box">
                    {project.goal2 && (
                      <div className="goal-box">
                        <div className="goal-box-inner">
                          <div className="goal-image">
                            <img src={images["aryoright.png"]} alt="" />
                          </div>
                          <div className="goal-info">
                            <p>{project.goal2}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="linkPath">
          <div className="navbar-ps">
            <div className="category-ps">
              <p id="cat-name">{category}</p>
            </div>
          </div>
        </div>
        <AllProjectsShimmer />
        <AllProjectsShimmer />
        <AllProjectsShimmer />
        <AllProjectsShimmer />
        <AllProjectsShimmer />
      </>
    );
  }


}
