import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SnapshotsShimmer() {
  return (
    <>
      <div className="snapshots-container" style={{ marginTop: "2px" }}>
        <h5 className="title-shimmer">
          <Skeleton />
        </h5>

        <div className="container-one">
          <div className="leads-count-shimmer">
            <Skeleton height={64} />
          </div>

          <div className="leads-count-shimmer">
            <Skeleton height={64} />
          </div>
        </div>

        <div className="container-two">
          <div className="leads-count-shimmer">
            <Skeleton height={64} />
          </div>

          <div className="leads-count-shimmer">
            <Skeleton height={64} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SnapshotsShimmer;
