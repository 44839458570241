
import { initializeApp } from 'firebase/app'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential,
    PhoneAuthProvider, onAuthStateChanged,  signOut, signInWithCustomToken, updateEmail } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";

import { getFirestore, getDocs, getDoc, doc, collection, query, where, orderBy, setDoc, Timestamp, updateDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey:  process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

const firebaseConfigDefault = {
  databaseURL: process.env.REACT_APP_FIREBASE_DEFAULT_RTDB,
};

const firebaseConfigAllProjects = {
  databaseURL: process.env.REACT_APP_FIREBASE_ALL_DETAILS_DESC_RTDB,
} 

const firebaseConfigProjectDetails = {
  databaseURL: process.env.REACT_APP_FIREBASE_PROJECT_DETAILS_RTDB
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestoreDB = getFirestore(app);
// const rtdb = getDatabase(app);
const defaultRtdb = getDatabase(initializeApp(firebaseConfigDefault, 'default'));
const allProjDescRtdb = getDatabase(initializeApp(firebaseConfigAllProjects, 'all-project-desc'));
const projectDetailsRtdb = getDatabase(initializeApp(firebaseConfigProjectDetails,'project-details'));

var uid;
var distributorUid;

export function setUid(id) {
  uid = id;
}

export function getUid() {
  if (uid === undefined) {
    //get from localstorage
    uid = JSON.parse(window.localStorage.getItem("userId"));
  }
  return uid;
}

export function getDistributorId() {
  if (distributorUid === undefined) {
    //get from localstorage
    distributorUid = JSON.parse(window.localStorage.getItem("dUid"));
  }
  return distributorUid;
}

export function getDistributorDetails(uid, callBack) {
  if (distributorUid === undefined) {
    distributorUid = JSON.parse(window.localStorage.getItem("dUid"));
    // console.log("distributorUid from localStorage ", distributorUid)
  }
  // console.log(uid, " current ditributor ", distributorUid)
  const docRef = doc(firestoreDB, `/AryoDB/${distributorUid}`);
  getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      callBack(distributorUid, docSnap.data().name, docSnap.data().mobile);
    } else {
      callBack("", "");
    }
  });
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    uid = user.uid;
  } else {
    // User is signed out
    // ...
  }
});


export async function getOwnerDetails(distbtrUid, callBack) {
  if (
    distbtrUid === "null" ||
    distbtrUid === "undefined" ||
    distbtrUid === ""
  ) {
    callBack("failure");
  } else {
    const docRef = doc(firestoreDB, `/AryoDB/${distbtrUid}`);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        callBack(docSnap.data());
      } else {
        callBack("failure");
      }
    });
  }
}

export async function getAgentDetails(dUid, uid, callBack) {
  const agentDoc = doc(firestoreDB, `/AryoDB/${dUid}/AgentsDB/${uid}`);
  getDoc(agentDoc).then((docSnap) => {
    if (docSnap.exists()) {
      callBack(docSnap.data());
    } else {
      callBack("failure");
    }
  });
}

export async function submitAgentProfile(ownerId, agentId, profile, callBack) {
  const profileDoc = doc(firestoreDB, `AryoDB/${ownerId}/AgentsDB/${agentId}`);
  setDoc(profileDoc, {
    name: profile.name,
    mobile: profile.mobile,
    email: profile.email,
    pincode: profile.pincode,
    dateOfRegistration: Timestamp.now().toDate(),
  }).then((doc) => {
    callBack("success");
  });
}

export async function getProductBanners(callBack) {
  let path = "TEAMSBANNER";
  const bannersRef = ref(defaultRtdb, path);
  const snapshot = await get(bannersRef);
  if (snapshot.exists()) {
    callBack(snapshot.val());
  } else {
    callBack(null);
  }
}

export async function updateAgentDetails(ownerId, agentId, profile, callBack) {
  const profileDoc = doc(firestoreDB, `AryoDB/${ownerId}/AgentsDB/${agentId}`);
  updateDoc(profileDoc, {
    name: profile.name,
    email: profile.email,
    pincode: profile.pincode,
    lastUpdatedOn: Timestamp.now().toDate(),
  }).then((doc) => {
    callBack("success");
  });
}


export async function getLiveProjects(category, callBack) {
  
  const projRef = ref(allProjDescRtdb, category);
  const snapshot = await get(projRef);

  try {
      if (snapshot.exists()) {
        var projects = [];
        if (snapshot.val() !== null) {
          Object.entries(snapshot.val()).map((proj) => {
            if (proj[1].ISHIDE === false && proj[1].ISONHOLD === false) {
              projects.push({
                name: proj[0],
                institution: proj[1].INSTITUTION,
                institutionLogo: proj[1].INSTITUTION_LOGO,
                goal1: proj[1].GOAL1,
                goal2: proj[1].GOAL2,
                heading: proj[1].HEADING,
                isHide: proj[1].ISHIDE,
                onHold: proj[1].ISONHOLD,
                logo: proj[1].LOGO,
                usp1: proj[1].USP1,
                usp2: proj[1].USP2,
              });
            }
          });
        }
        callBack(projects);
      }
      else {
      callBack(null);
    }
  } catch (error) {
    console.log("Error getting live projects", error);
    callBack(null);
  }
}

export async function getOnHoldProjects(category, callBack) {
  const projRef = ref(allProjDescRtdb, category);
  const snapshot = await get(projRef);
  try {
    if (snapshot.exists()) {
      if (snapshot.exists()) {
        var projects = [];
        if (snapshot.val() !== null) {
          Object.entries(snapshot.val()).map((proj) => {
            if (proj[1].ISHIDE === false && proj[1].ISONHOLD === true) {
              projects.push({
                name: proj[0],
                institution: proj[1].INSTITUTION,
                institutionLogo: proj[1].INSTITUTION_LOGO,
                goal1: proj[1].GOAL1,
                goal2: proj[1].GOAL2,
                heading: proj[1].HEADING,
                isHide: proj[1].ISHIDE,
                onHold: proj[1].ISONHOLD,
                logo: proj[1].LOGO,
                usp1: proj[1].USP1,
                usp2: proj[1].USP2,
              });
            }
          });
        }
        callBack(projects);
      }
    } else {
      callBack(null);
    }
  } catch (error) {
    console.log("Error getting hold projects", error);
    callBack(null);
  }
}

var allLeadsList = [];

export async function getAryoDistLeads(uid, dUid, callBack) {

  const leadsDBRef = collection(firestoreDB, "AryoDistLeadsDB");
  const q = query(
    leadsDBRef,
    where("agentId", "==", uid),
    where("DistId", "==", dUid),
    orderBy("dateOfSubmission", "desc")
  );

  allLeadsList = [];
  getDocs(q).then((leads) => {
    let cnt = 0;
    if (leads.size > 0) {

      leads.forEach((lead) => {
        cnt++;
        allLeadsList.push(lead.data());
        if (cnt === leads.size) {
          allLeadsList.reverse();
          callBack(allLeadsList);
        }
      });
    } else {
      callBack(allLeadsList);
    }
  });
}

export function getApprovedLeadsCount() {
  return allLeadsList.filter((lead) => lead.status === "Approved").length;
}

export function getInprocessLeadsCount() {
  return allLeadsList.filter((lead) => lead.status === "In process").length;
}

export function getRejectedLeadsCount() {
  return allLeadsList.filter((lead) => lead.status === "Rejected").length;
}

export function getSubmittedLeadsCount() {
  return allLeadsList.length;
}

export async function getAllLogos(callBack) {
  let path = "PROJECTLOGOS";
  const logosRef = ref(defaultRtdb, path);
  const snapshot = await get(logosRef);
  if (snapshot.exists()) {
    callBack(snapshot.val());
  } else {
    callBack(null);
  }
}


export async function getProjectDetails(projectName, leadCategory, callBack) {
  try {
    const projRef = ref(projectDetailsRtdb, leadCategory + "/" + projectName);
    const snapshot = await get(projRef);
    if (snapshot.exists()) {
      let val = snapshot.val();
      let projectData = {};
      if (val !== null) {
        projectData = {
          status: "success",
          projectName: projectName,
          bannerURL: val.CUSTOMERBANNER,
          leadCategory: leadCategory,
          projectLogo: val.LOGO,
          isOnHold: val.ISONHOLD,
          projectDetails: val.PROJECTDETAILS,
          error: "",
          document: val.DOCREQUIRED,
          dosNdonts: val.DOSNDONTS,
          eligibility: val.ELIGIBILITY,
          guide: val.GUIDE,
          link: val.TEAMURL,
          message: val.MESSAGE,
        };
      }
      callBack(projectData);
    } else {
      callBack(null);
    }
  } catch (error) {
    console.log("Error getting project details", error);
  }
}

export {
  app,
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateEmail,
  getDocs,
  onAuthStateChanged,
  signOut,
  getFirestore,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithCustomToken,
};