import axios from "axios";
const URL = "https://aryoconnect.in/";
// const URL = "http://192.168.1.7:8080/";
// const URL_UAT = "http://34.31.137.130:80/";

export async function generateOtp(mobile, callBack) {
  const result = await axios.post(URL + "sendOtp", { mobile: mobile , isTeam : true });
  callBack(result.data);
  // callBack({status: 'success', orderId: '1703784067285'})
}

export async function reGenerateOtp(orderId, callBack) {
  const result = await axios.post(URL + "resendOtp", { orderId: orderId });
  callBack(result.data);
  // callBack({status: 'success', orderId: '1703784067285'})
}

export async function getFirebaseToken(mobile, callBack) {
  const result = await axios.get(URL + "getCustomToken/?mobile=" + mobile);
  callBack(result.data);
}

export async function verifyOtp(mobile, orderId, otp, callBack) {
  const result = await axios.post(URL + "verifyOtp", {
    mobile: mobile,
    orderId: orderId,
    otp: otp,
  });
  callBack(result.data);
  // callBack({'status': 'success', 'isOTPVerified': true});
}

export async function checkPincode(pincode, callBack) {
  console.log(pincode);
  const result = await axios.get(URL + "checkPincode", {
    params: {
      pincode: pincode,
    },
  });
  callBack(result.data);
}
