import React from "react";
import { importAll } from "./Helper";
import "../css/Navbar.css";
import { getStoredOwner } from "./LocalStorage";

export default function AgentHeader(props) {
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );
  const owner = getStoredOwner();
  return (
    <div className="navbar-container">
      {/* <img src={images["Profile.svg"]} className="ownericon" alt="" /> */}
      <div className="agent">
        <p className="agentname">Hello {props.name}!</p>
        <p className="agentmobile">{props.mobile}</p>
      </div>

      <div className="owner-container">
        <img src={images["owner.png"]} className="ownericon" alt="" />
        <div className="agent">
          <p className="ownername">{owner.name}!</p>
          <p className="ownermobile">{owner.mobile}</p>
        </div>
      </div>
    </div>
  );
}
