import React, { useEffect } from "react";
import "../css/Category.css";
import { auth, signOut } from "../Firebase";
import { getStoredAgent } from "./LocalStorage";
import { importAll } from "./Helper";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

export default function Loans() {
  const [searchParams] = useSearchParams();
  const dUid = searchParams.get("dist");
  const navigation = useNavigate();
  const agent = getStoredAgent();

  useEffect(() => {
    if (agent === null) {
      logOut();
    }
  },[]);

  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <div className="agentinfobox-container-loans ">
      <div className="category-heading">
        <p className="category-title">Loans</p>
      </div>

      {/* Categories */}
      <div className="grid">
        <Link
          className="linkClass"
          to={{
            pathname: "/Projects",
            search: `?category=Instant loan&dist=${dUid}`,
          }}
        >
          <div className="grid-item">
            <img src={images["il.png"]} alt="" className="categoryImage" />
            <p className="categoryName">Instant Loan</p>
          </div>
        </Link>

        <Link
          className="linkClass"
          to={{
            pathname: "/Projects",
            search: `?category=Personal loan&dist=${dUid}`,
          }}
        >
          <div className="grid-item">
            <img src={images["pl.png"]} alt="" className="categoryImage" />
            <p className="categoryName">Personal Loan</p>
          </div>
        </Link>

        <Link
          className="linkClass"
          to={{
            pathname: "/Projects",
            search: `?category=Business loan&dist=${dUid}`,
          }}
        >
          <div className="grid-item">
            <img src={images["bl.png"]} alt="" className="categoryImage" />
            <p className="categoryName">Business loan</p>
          </div>
        </Link>
      </div>
    </div>
  );

  function logOut() {
    signOut(auth)
      .then(() => {
        window.localStorage.clear();
        navigation({
          pathname: "/",
          search: `?dist=${dUid}`,
          logout: true,
        });
      })
      .catch((error) => {});
  }
}
