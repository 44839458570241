import React, { useState } from "react";
import * as Validation from "./Validations";
import { importAll } from "./Helper";
import { getStoredOwner } from "./LocalStorage.js";
import "../css/Login.css";

export default function Login(props) {

  const owner = getStoredOwner();
  const formElements = { mobile: "" };
  const [formData, setFormData] = useState(formElements);
  const [formError, setFormError] = useState({ mobile: "" });
  const [isError, setIsError] = useState(false);

  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formData.mobile !== "") {
      setIsError(true);
      setFormError({ mobile: "" });
    }
  };

  const onMobileInputKeyDown = (e) => {
    if (!/^[0-9]$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const getOtp = (event) => {
    event.preventDefault();
    const error = Validation.validateMobileNumber(formData.mobile);
    if (error) {
      setFormError({ mobile: error });
      setIsError(true);
    } else {
      props.getLoginCallBack(formData.mobile);
    }
  };

  return (
          <div className="login-page">
            <div className="owner-box desktop">
              <div className="ownerDetails">
                <p>Business Owner</p>
                <p>{owner.name}</p>
                <p>{owner.mobile}</p>
              </div>
            </div>

            <div className="mainbox-login">
              <div className="main-container-login">
                <div className="cover-image">
                  <img src={images["stock11.png"]} alt="" />
                </div>

                <div className="login-card">
                  <div className="login-card-one">
                    <div className="owner-box mobile">
                      <div className="ownerDetails">
                        <p>Business Owner</p>
                        <p>{owner.name}</p>
                        <p>{owner.mobile}</p>
                      </div>
                    </div>

                    <div className="login-welcomeMessage">
                      <div className="messageMobile">
                        <p>
                          Dear <span>Team Member</span>
                        </p>
                        <p>
                          Sign in to experience a wide range of financial
                          products!!
                        </p>
                      </div>
                      <div className="signIn">
                        <p>Sign In/Sign Up</p>
                      </div>
                    </div>

                    <div className="inputBox">
                      <input
                        type="tel"
                        name="mobile"
                        value={formData.mobile}
                        required="required"
                        id="mobileId"
                        minLength="1"
                        maxLength="10"
                        placeholder="Enter your Mobile Number"
                        onInvalid={(e) =>
                          e.target.setCustomValidity("Enter valid mobile")
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        onKeyDown={onMobileInputKeyDown}
                        onChange={onInputChange}
                      />
                      <i></i>
                    </div>

                    {isError && (
                      <div className="error-message">{formError.mobile}</div>
                    )}
                    <br />

                    <div className="Button">
                      <button
                        type="submit"
                        className="btn btn-primary my-2 getotpbtnId"
                        id="getotpbtnId"
                        onClick={getOtp}
                      >
                        Generate OTP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quickLinks">
              <div className="tagline">
                <p>
                  Our
                  <span> Product Portfolio</span>
                </p>
              </div>
              <div className="boxes">
                <div className="box1">
                  <img src={images["demat_new.png"]} alt="" />
                </div>
                <div className="box2">
                  <img src={images["savings_acc_new.png"]} alt="" />
                </div>
                <div className="box3">
                  <img src={images["cc_new.png"]} alt="" />
                </div>
                <div className="box4">
                  <img src={images["loan_new.png"]} alt="" />
                </div>
                <div className="box5">
                  <img src={images["bnpl_new.png"]} alt="" />
                </div>
              </div>
            </div>
          </div>
  );
}
