import React from "react";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import ReactWhatsapp from "react-whatsapp";
import no_leads from "../images/no_leads.png";
import whatsapp from "../images/watsapp.png";
import aryo from "../images/aryoright.png";

function LeadsView({ allLeads }) {
  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  return (
    <>
      {allLeads.length !== 0 ? (
        <>
          {" "}
          <div
            style={{ width: "96%", height: "82vh" }}
            className="leads-wrapper"
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  deferredMeasurementCache={cache.current}
                  rowCount={allLeads.length}
                  rowHeight={cache.current.rowHeight}
                  rowRenderer={({ key, index, style, parent }) => {
                    const lead = allLeads[index];
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <div style={style} key={key}>
                          <div className="leads-container">
                            <div className="lead-name-section">
                              <div>
                                <p className="agent_name_logo">
                                  {lead.name.charAt(0).toUpperCase()}
                                </p>
                              </div>
                              <div className="agent_detail">
                                <p className="agent_nm">{lead.name}</p>
                                <p>
                                  {lead.mobile}{" "}
                                  <span>
                                    <ReactWhatsapp
                                      className="openwatsapp"
                                      number={"+91" + lead.mobile}
                                      message="Hi"
                                    >
                                      <img
                                        src={whatsapp}
                                        alt=""
                                        className="watsappiconallleads"
                                      />
                                    </ReactWhatsapp>
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="lead-project-section">
                              <div className="lead_project_logo">
                                <div>
                                  <img
                                    src={lead.logo}
                                    alt=""
                                    className="projectImage"
                                  />
                                </div>
                                <div className="lead-project_detail">
                                  <p className="lead-nm">{lead.projName}</p>
                                  <p className="lead-ct">{lead.leadCat} </p>
                                </div>
                              </div>
                              <div>
                                <p className="submit-title">Submitted On</p>
                                <p className="submit-date">{lead.dateOfSub} </p>
                              </div>
                            </div>
                            <div className="lead-status-section">
                              <div className="lead_status">
                                <div className="goal_info">
                                  <div className="g1">
                                    <img
                                      src={aryo}
                                      alt=""
                                      className="aryo_icon"
                                    />
                                  </div>
                                  <div className="g2">
                                    <p className="goal_name">{lead.goal1}</p>
                                  </div>
                                </div>
                                <div className="g3">
                                  <p className="goal_status">
                                    {lead.status && <span>|</span>}{" "}
                                    {lead.status}
                                  </p>
                                </div>
                              </div>
                              {lead.incStatus && (
                                <div className="lead_status">
                                  <div className="goal_info">
                                    <div className="g1">
                                      <img
                                        src={aryo}
                                        alt=""
                                        className="aryo_icon"
                                      />
                                    </div>
                                    <div className="g2">
                                      <p className="goal_name">{lead.goal2}</p>
                                    </div>
                                  </div>
                                  <div className="g3">
                                    <p className="goal_status">
                                      {lead.incStatus && <span>|</span>}{" "}
                                      {lead.incStatus}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {lead.remark !== "" ? (
                                <div className="remark">
                                  <p>{lead.remark}</p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </>
      ) : (
        <>
          <div className="leads-image">
            <img src={no_leads} alt="" />
            <p id="no_leads">No matched leads found</p>
          </div>
        </>
      )}
    </>
  );
}

export default LeadsView;
