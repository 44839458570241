import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error from "./Error";
import Home from "./Components/Home";
import Loans from "./Components/Loans";
import AllProjects from "./Components/AllProjects";
import ProjectShare from "./Components/ProjectShare";
import UpdateUser from "./Components/UpdateUser";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Home" element={<Home />} />
      <Route path="UpdateProfile" element={<UpdateUser/>}/>
      <Route path="/Projects" element={<AllProjects />} />
      <Route path="Projects/Loans" element={<Loans />} />
      <Route path="*" element={<Error />} />
      <Route path="/ProjectShare" element={<ProjectShare />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
