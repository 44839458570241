import React, { useEffect, useState } from "react";
import { getProjectDetails } from "../Firebase";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarPS from "./NavbarPS";
import BannerPS from "./BannerPS";
import DetailsPS from "./DetailsPS";
import "../css/ProjectShare.css";
import { getStoredAgent } from "./LocalStorage";

function ProjectShare() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category");
  const projectName = searchParams.get("projectName");
  const owner = searchParams.get("dist");
  const agent = getStoredAgent();
  const navigation = useNavigate();

  const projectELements = {
    name: "",
    banner: "",
    guide: "",
    dosNdonts: "",
    eligibility: "",
    documents: "",
    projectDetails: "",
    link: "",
    message: "",
  };
  const [projectData, setProjectData] = useState(projectELements);



  useEffect(() => {
    if (agent === null) {
      window.localStorage.clear();
      navigation({
        pathname: "/",
        search: `?dist=${owner}`,
        logout: true,
      });
    } 

      getProjectDetails(projectName, category, function (res) {
        setProjectData({
          ...projectData,
          name: res.projectName,
          banner: res.bannerURL,
          guide: res.guide,
          dosNdonts: res.dosNdonts,
          eligibility: res.eligibility,
          documents: res.document,
          projectDetails: res.projectDetails,
          link: res.link,
          message: res.message,
        });
      });
    
  }, []);

  return (
    <>
      <div className="ps-container">
        <NavbarPS name={projectData.name} category={category}></NavbarPS>
        <div className="ps-scroller">
          <BannerPS banner={projectData.banner}></BannerPS>
          <DetailsPS
            dUid={owner}
            projectData={projectData}
            aUid={(agent === null ? '' :  agent.uid)}
            banner={projectData.banner}
            guide={projectData.guide}
            dosNdonts={projectData.dosNdonts}
            eligibility={projectData.eligibility}
            documents={projectData.documents}
            projectDetails={projectData.projectDetails}
            link={projectData.link}
            message={projectData.message}
          ></DetailsPS>
        </div>
      </div>
    </>
  );
}

export default ProjectShare;
