import React, { useState, useRef, useEffect } from "react";
import * as RANGE from './Constants';
import * as KEY from './Keys';
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function DateFilter({ handleFixedDaysFilter, renderOnClear, onClearFilter , handleDateSelectionStatus }) 
{
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: RANGE.SELECTION,
    },
  ]);

  const [dateSelected, setDateSelected] = useState(false);

  const storedSelectedRange = JSON.parse(localStorage.getItem("selectedRange")) || [];
  const [selectedRange, setSelectedRange] = useState(storedSelectedRange);

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);

    // Check local storage for saved start and end dates
    const savedStartDate = localStorage.getItem("selectedStartDate");
    const savedEndDate = localStorage.getItem("selectedEndDate");

    if (savedStartDate && savedEndDate) {
      setRange([
        {
          startDate: new Date(savedStartDate),
          endDate: new Date(savedEndDate),
          key: RANGE.SELECTION,
        },
      ]);
      setDateSelected(true);
    }
  }, []);

  useEffect(() => {
    if (renderOnClear) {
      setSelectedRange(null);
      onClearFilter();
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: RANGE.SELECTION,
        },
      ]);
      setDateSelected(false);
    }
  }, [renderOnClear]);

  const handleCustomDate = () => {
    if(selectedRange === RANGE.CUSTOM){
      const sDate = new Date();
      const eDate = new Date();
      localStorage.setItem("startDate", null);
      localStorage.setItem("endDate", null);
      setSelectedRange(null);
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: RANGE.SELECTION,
        },
      ]);
      setDateSelected(false);
      handleDateSelectionStatus(false)
      localStorage.setItem("dateSelectionStatus", false);
      handleFixedDaysFilter(sDate, eDate);
    }
    else
    {
      setOpen(!open)
    }
   
    
  }

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === KEY.ESCAPE) {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleOkClick = () => {
    setOpen(false);
    const sDate = range[0].startDate;
    const eDate = range[0].endDate;
    localStorage.setItem("startDate", sDate ? sDate.toISOString() : null);
    localStorage.setItem("endDate", eDate ? eDate.toISOString() : null);
    localStorage.setItem( "selectedStartDate", sDate ? sDate.toISOString() : null );
    localStorage.setItem("selectedEndDate", eDate ? eDate.toISOString() : null);
    handleFixedDaysFilter(sDate, eDate);
    setSelectedRange(RANGE.CUSTOM);
    setDateSelected(true);
    handleDateSelectionStatus(true)
    localStorage.setItem("dateSelectionStatus", true);
  };

  useEffect(() => {
    localStorage.setItem("selectedRange", JSON.stringify(selectedRange));
  }, [selectedRange]);

  const handleCancelClick = () => {
    const storedStartDate = localStorage.getItem("selectedStartDate");
    const storedEndDate = localStorage.getItem("selectedEndDate");

    setRange([
      {
        startDate: storedStartDate ? new Date(storedStartDate) : new Date(),
        endDate: storedEndDate ? new Date(storedEndDate) : new Date(),
        key: RANGE.SELECTION,
      },
    ]);

    setOpen(false);
  };

  const handleDaysFilter = (days) => {
    const sDate = new Date();
    const eDate = new Date();

    const isSameRange = selectedRange === days;


    if (isSameRange) {
      localStorage.setItem("startDate", null);
      localStorage.setItem("endDate", null);
      setSelectedRange(null);
      handleDateSelectionStatus(false);
      localStorage.setItem("dateSelectionStatus", false);
    } else {
      sDate.setDate(sDate.getDate() - days);
      localStorage.setItem("startDate", sDate.toISOString());
      localStorage.setItem("endDate", eDate.toISOString());
      setSelectedRange(days);
      setRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: RANGE.SELECTION,
        },
      ]);
      setDateSelected(false);
      handleDateSelectionStatus(true);
      localStorage.setItem("dateSelectionStatus", true);

    }


    handleFixedDaysFilter(sDate, eDate);
  };

  return (
    <div className="f-wrapper">
      <p className="f-heading">Date Range</p>
      <p
        className={`days_range ${selectedRange === 7 ? "selected" : ""}`}
        onClick={() => handleDaysFilter(7)}
      >
        Last 7 days 
      </p>
      <p
        className={`days_range ${selectedRange === 30 ? "selected" : ""}`}
        onClick={() => handleDaysFilter(30)}
      >
        Last 30 days
      </p>

      <div className="calendarWrap">
        <input
          value={
            range[0].startDate && range[0].endDate && dateSelected
              ? `${format(range[0].startDate, "dd/MM/yyyy")} to ${format(
                  range[0].endDate,
                  "dd/MM/yyyy"
                )}`
              : "Select Date"
          }
          readOnly
          className={`inputBox ${selectedRange === RANGE.CUSTOM ? "selected" : ""}`}
          onClick={() => handleCustomDate()}
          // placeholder="Select Date"
          // setOpen((open) => !open)
        />

        <div ref={refOne}>
          {open && (
            <div className="date-wrapper">
              <div className="date-container">
                <DateRange
                  onChange={(item) => setRange([item.selection])}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  direction="horizontal"
                  className="calendarElement"
                />
                <div className="date_button">
                  <button onClick={handleOkClick} id="date_ok">
                    Ok
                  </button>
                  <button onClick={handleCancelClick} id="date_cancel">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DateFilter;
