export const ALL = 'all';
export const TEN = '10';
export const SEVEN = '7';
export const THIRTY = '30';
export const CATEGORY = 'Category';
export const LEAD_STATUS = 'Lead Status';
export const PROJECT = 'Project';
export const PAYOUT_STATUS = 'Payout Status';
export const SEVEN_DAYS = 'Last 7 days';
export const THIRTY_DAYS = 'Last 30 days';
export const TEN_DAYS = 'Last 10 Leads';
export const MORE_FILTER = 'More Filter';
export const CUSTOM = 'custom';
export const SELECTION = 'selection';