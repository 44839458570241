import { useState, useEffect } from "react";
import { SearchContainer } from "./SearchContainer";
import LeadsView from "./LeadsView";
import ExportLeads from "./ExportLeads";
import AllProjectsShimmer from "./AllProjectsShimmer";
import Filter from "./Filter";
import { useNavigate } from "react-router-dom";
import { auth, signOut, getAryoDistLeads, getAllLogos } from "../Firebase";
import { getStoredAgent, removeStoredFilter } from "./LocalStorage";
import { getMonthInString } from "./Helper";
import filter_icon from "../images/filter_icon_new.png";
import "../css/AllLeadsDetails.css";

export default function AllLeadsDetails({ dUid }) {
  const agent = getStoredAgent();
  const navigation = useNavigate();

  var [allSubmittedLeads, setAllSubmittedLeads] = useState([]);
  var [allLeads, setAllLeads] = useState([]);
  var [allFiltredLeads, setAllFilteredLeads] = useState([]);
  var [leadsFromMoreFilter, setLeadsFromMoreFilter] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [showShimmer, setShowShimmer] = useState(true);
  const [showLeads, setShowLeads] = useState(false);

  const showFilteredLeads = (value) => {
    setTimeout(() => {
      setShowLeads(true);
      setShowShimmer(false);
    }, 1000);
    setShowFilter(false);
    setShowShimmer(true);
    setAllLeads([...value]);
    setLeadsFromMoreFilter([...value]);
    setAllFilteredLeads([...value]);
  };

  const closeFilter = (value) => {
    setTimeout(() => {
      setShowLeads(true);
      setShowShimmer(false);
    }, 500);
    setShowFilter(false);
    setShowShimmer(true);
    // setLeadsFromMoreFilter([...value]);
  };

  const openFilter = () => {
    setShowLeads(false);
    setShowFilter(true);
  };

  useEffect(() => {
    if (agent === null) {
      logOut();
    }

    getAllLogos((res) => {
      getAryoDistLeads(agent.uid, dUid, (response) => {
        filterLeads(response, res);
      });
    });
    removeStoredFilter();
  }, []);

  function filterLeads(response, res) {
    var ind = 0;
    var agentLeads = [];

    while (response.length !== 0) {
      let lead = response.pop();

      const date = new Date(lead.dateOfSubmission.seconds * 1000);
      const _dateOfSub =
        date.getDate() +
        "-" +
        getMonthInString(date.getMonth()) +
        "-" +
        date.getFullYear();

      function capitalizeAllWords(str) {
        return str.replace(/\b\w/g, (c) => c.toUpperCase());
      }
      
      const _lead = {
        id: ind + 1,
        name: lead.customerName,
        mobile: lead.customerMobile,
        leadCat: capitalizeAllWords(lead.leadCategory.toLowerCase()),
        projName: capitalizeAllWords(lead.projectName.toLowerCase()),
        dateOfSub: _dateOfSub,
        payoutState: lead.payoutState,
        remarks: lead.remarks,
        goal1: lead.goal1 !== undefined ? lead.goal1 : "Lead Status",
        status: lead.status,
        goal2:
          lead.goal2 !== undefined
            ? lead.goal2
            : "Lead Status (Additional Earning)",
        incStatus: lead.incStatus,
        remark: "",
        logo:
          res[lead.projectName] !== undefined
            ? res[lead.projectName]
            : res["Aryo"],
      };

      ind++;
      agentLeads.push(_lead);
    }

    setAllSubmittedLeads([...agentLeads]);
    setAllLeads([...agentLeads]);
    setAllFilteredLeads([...agentLeads]);
    setShowShimmer(false);
    setShowLeads(true);
    setShowFilter(false);
    removeStoredFilter();
  }

  function onSearchStart(e) {
    e.preventDefault();
    const searchTerm = e.target.value.toLowerCase();

    let searchLeads = allFiltredLeads.filter((lead) => {
      const nameMatch = lead.name.toLowerCase().includes(searchTerm);
      const phoneMatch = lead.mobile.toLowerCase().includes(searchTerm);

      return nameMatch || phoneMatch;
    });
    setAllLeads([...searchLeads]);
  }

  return (
    <>
      {showFilter && (
        <Filter
          showFilteredLeads={showFilteredLeads}
          closeFilter={closeFilter}
          allSubmittedLeads={allSubmittedLeads}
          leadsFromMoreFilter={leadsFromMoreFilter}
          setLeadsFromMoreFilter={setLeadsFromMoreFilter}
        />
      )}
      {showLeads && (
        <>
          <SearchContainer
            onSearchStart={onSearchStart}
            openFilter={openFilter}
          />
          <div className="filter-option-container">
            <div className="filter-option">
              <div className="export_button">
                <ExportLeads allLeads={allLeads} />
              </div>
              {"|"}
              <div
                className="filter_button"
                onClick={() => {
                  openFilter();
                }}
              >
                <img src={filter_icon} alt="" id="filter_icon" />
                <p
                  className={
                    allSubmittedLeads.length !== allLeads.length
                      ? "has-filter"
                      : ""
                  }
                >
                  Filter
                </p>
              </div>
            </div>
          </div>
          <div style={{marginTop : '42px'}}>
            <LeadsView allLeads={allLeads} />
          </div>
        </>
      )}
      {showShimmer && 
      <AllProjectsShimmer />}
    </>
  );

  function logOut() {
    signOut(auth)
      .then(() => {
        window.localStorage.clear();
        navigation({
          pathname: "/",
          search: `?dist=${dUid}`,
          logout: true,
        });
      })
      .catch((error) => {});
  }
}
