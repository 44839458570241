import React, {  useEffect, useState } from "react";
import "../css/filter.css";

const LeadFilter = ({ handleChangeLeadsStatus, handleSpinner, uniqueLeadStatus, renderOnClear, onClearFilter } ) => {

  const storeLeadStatus =
    JSON.parse(localStorage.getItem("selectedLeadStatus")) || [];
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(storeLeadStatus);


  useEffect(() => {
    if (renderOnClear) {
      setSelectedLeadStatus([]);
      onClearFilter();
    }
  }, [renderOnClear]);

  const handleCheckboxChange = (leadStatus) => {
    handleSpinner();
    const updateSelection = [...selectedLeadStatus];

    if (updateSelection.includes(leadStatus)) {
      updateSelection.splice(updateSelection.indexOf(leadStatus), 1);
    } else {
      updateSelection.push(leadStatus);
    }

    setSelectedLeadStatus(updateSelection);
    localStorage.setItem("selectedLeadStatus", JSON.stringify(updateSelection));

    handleChangeLeadsStatus(updateSelection);
  };

  return (
    <div className="f-wrapper">
      <p className="f-heading">Lead Status</p>
      <div className="f-data">
        {uniqueLeadStatus.map((status, index) => (
          <div key={index}>
            <input
              type="checkbox"
              id={`status${index}`}
              value={status}
              checked={selectedLeadStatus.includes(status)}
              onChange={() => handleCheckboxChange(status)}
            />
            <label htmlFor={`status${index}`} id="state_name">{status}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadFilter;
