import React, { useEffect, useState } from "react";
import { isSelectedFilterNotEmpty } from "./LocalStorage";
import { formatName } from "./FormatName";
import CategoryFilter from "./CategoryFilter";
import Leadfilter from "./Leadfilter";
import PaymentFilter from "./PaymentFilter";
import DateFilter from "./DateFilter";
import Spinner from "./Spinner";
import { removeStoredFilter } from "./LocalStorage";
import "../css/filter.css";

const filterComponents = {
  'Category': CategoryFilter,
  "Lead Status": Leadfilter,
  "Payout Status": PaymentFilter,
  'Date': DateFilter,
};

function Filter({
  showFilteredLeads,
  closeFilter,
  allSubmittedLeads,
  leadsFromMoreFilter,
  setLeadsFromMoreFilter,
}) {
  const [activeFilter, setActiveFilter] = useState("Category");

  const [leads, setLeads] = useState([]);

  const [showSpinner, setShowSpinner] = useState(false);

  const [selectedProjects, setSelectedProjects] = useState(
    JSON.parse(localStorage.getItem("selectedProjects")) || []
  );
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(
    JSON.parse(localStorage.getItem("selectedPaymentStatus")) || []
  );
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(
    JSON.parse(localStorage.getItem("selectedLeadStatus")) || []
  );
  const [isDateSelected, setIsDateSelected] = useState(
    localStorage.getItem("dateSelectionStatus") === "true"
  );

  const [renderOnClear, setRenderOnClear] = useState(false);

  const [uniqueProject, setUniqueProject] = useState([]);
  const [uniqueLeadStatus, setUniqueLeadStatus] = useState([]);
  const [uniquePaymentStatus, setUniquePaymentStatus] = useState([]);

  const onClearFilter = () => {
    setRenderOnClear(false);
  };

  // Project Filter(Checked)
  const handleChangeProjects = (value) => {
    setSelectedProjects(value);
  };

  // LeadStatusFilter(Checked)
  const handleChangeLeadsStatus = (value) => {
    setSelectedLeadStatus(value);
  };

  // PayoutStatus Filter(Checked)
  const handleChangePaymentStatus = (value) => {
    setSelectedPaymentStatus(value);
  };

  const handleFixedDaysFilter = (start_Date, end_Date) => {
    if (start_Date && end_Date) {
      getSelectedLeads();
    }
  };

  const handleDateSelectionStatus = (value) => {
    setIsDateSelected(value);
  };

  useEffect(() => {
    if (
      selectedProjects.length > 0 ||
      selectedLeadStatus.length > 0 ||
      selectedPaymentStatus.length > 0
    ) {
      getSelectedLeads();
    } else if (
      selectedProjects.length === 0 ||
      selectedLeadStatus.length === 0 ||
      selectedPaymentStatus.length === 0
    ) {
      getSelectedLeads();
    } else {
    }
  }, [selectedProjects, selectedLeadStatus, selectedPaymentStatus]);

  // Checked
  function getSelectedLeads() {
    let leads = allSubmittedLeads;

    leads = leads.filter(
      (lead) =>
        (selectedProjects.length > 0
          ? selectedProjects.some(
              (item) =>
                item.project.toLowerCase() === lead.projName.toLowerCase() &&
                item.category.toLowerCase() === lead.leadCat.toLowerCase()
            )
          : uniqueProject.some(
              (project) => project.toLowerCase() === lead.projName.toLowerCase()
            )) &&
        (selectedLeadStatus.length > 0
          ? selectedLeadStatus.some(
              (leadStat) => leadStat.toLowerCase() === lead.status.toLowerCase()
            )
          : uniqueLeadStatus.some(
              (leadStatus) =>
                leadStatus.toLowerCase() === lead.status.toLowerCase()
            )) &&
        (selectedPaymentStatus.length > 0
          ? selectedPaymentStatus.some(
              (payment) =>
                payment.toLowerCase() === lead.payoutState.toLowerCase()
            )
          : uniquePaymentStatus.some(
              (paymentStatus) =>
                paymentStatus.toLowerCase() === lead.payoutState.toLowerCase()
            ))
    );

    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");

    if (
      startDate !== "null" &&
      endDate !== "null" &&
      startDate !== null &&
      endDate !== null
    ) {
      leads = leads.filter((lead) => {
        const leadDate = lead.dateOfSub ? new Date(lead.dateOfSub) : null;
        return (
          leadDate &&
          leadDate >= new Date(startDate) &&
          leadDate <= new Date(endDate)
        );
      });
    }

    setLeads([...leads]);
  }

  useEffect(() => {
    isSelectedFilterNotEmpty();
  }, [leads]);

  useEffect(() => {
    if (!isSelectedFilterNotEmpty()) {
      setShowSpinner(true);
      setLeads([...allSubmittedLeads]);
    } 
    else {
      setLeads([...leadsFromMoreFilter]);
    }

    const uniqueProjectSet = new Set(
      allSubmittedLeads.map((lead) => formatName(lead.projName))
    );
    setUniqueProject(Array.from(uniqueProjectSet));

    const uniqueLeadStatusSet = new Set(
      allSubmittedLeads.map((lead) => formatName(lead.status))
    );
    setUniqueLeadStatus(Array.from(uniqueLeadStatusSet));

    const uniquePaymentStatusSet = new Set(
      allSubmittedLeads.map((lead) => formatName(lead.payoutState))
    );
    setUniquePaymentStatus(Array.from(uniquePaymentStatusSet));

    setShowSpinner(false);
  }, [allSubmittedLeads]);

  const handleSpinner = () => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 100);
    setShowSpinner(true);
  };

  //On performing clear filter in Filter Component
  const clearFilter = () => {
    setLeads([...allSubmittedLeads]);
    setLeadsFromMoreFilter([]);
    setSelectedLeadStatus([]);
    setSelectedPaymentStatus([]);
    setSelectedProjects([]);
    setRenderOnClear(true);
    removeStoredFilter();
  };

  const handleChangeFilter = (selectedFilter) => {
    setActiveFilter(selectedFilter);
  };

  return (
    <div className="filter-wrapper">
          <div className="filter-header">
            <p>Filters</p>
            <p onClick={() => closeFilter(leads)}>Close</p>
          </div>
          <div className="filter-container">
            <div className="filter-list">
              <ul>
                {Object.keys(filterComponents).map((filter) => (
                  <li
                    key={filter}
                    onClick={() => handleChangeFilter(filter)}
                    data-value={filter}
                    className={`filter-name ${
                      activeFilter === filter ? "active" : ""
                    }`}
                  >
                    {filter}{" "}
                    {filter === "Category" && selectedProjects.length > 0
                      ? `(${selectedProjects.length})`
                      : ""}
                    {filter === "Lead Status" && selectedLeadStatus.length > 0
                      ? `(${selectedLeadStatus.length})`
                      : ""}
                    {filter === "Payout Status" &&
                    selectedPaymentStatus.length > 0
                      ? `(${selectedPaymentStatus.length})`
                      : ""}
                    {filter === "Date" && isDateSelected ? `(1)` : ""}
                  </li>
                ))}
              </ul>
            </div>
            <div className="filter-item">
              {React.createElement(filterComponents[activeFilter], {
                allSubmittedLeads,
                handleChangeProjects,
                handleChangeLeadsStatus,
                handleChangePaymentStatus,
                renderOnClear,
                onClearFilter,
                uniqueLeadStatus,
                uniquePaymentStatus,
                handleFixedDaysFilter,
                handleDateSelectionStatus,
                handleSpinner
              })}
            </div>
          </div>
          <div className="filter-bottom">
            <div
              className="clearFilter"
              onClick={() => {
                clearFilter();
                handleDateSelectionStatus(false);
              }}
            >
              <button className="filter-button clear">Clear Filters</button>
            </div>

            <div
              className="showFilter"
              onClick={() => showFilteredLeads(leads)}
            >
              <button className="filter-button">
                Show {leads.length} Results
              </button>
            </div>
          </div>
      {showSpinner && <Spinner/>}
    </div>
  );
}

export default Filter;
