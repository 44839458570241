import React, { useEffect, useState } from "react";
import { getAgentDetails, updateAgentDetails } from "../Firebase";
import { checkPincode } from "../Connection";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "./Spinner";
import Loader from "./Loader";
import * as Validation from "./Validations";
import * as States from "./States";
import MyModal from "./ShowModal";
import stock from "../images/signup.png";
import "../css/NewUser.css";
import "../css/UpdateUser.css";
import { getStoredAgent, storeAgent } from "./LocalStorage";

export default function UpdateUser() {
  const [searchParams] = useSearchParams();
  const dUid = searchParams.get("dist");
  const aUid = searchParams.get("agentId");
  const [isLoadingPincode, setIsLoadingPincode] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPincodeVerified, setIsPincodeVerified] = useState(false);
  const navigation = useNavigate();
  const agent = getStoredAgent();
  const [showModal, setShowmodal] = useState(false);

  const [memberData, setMemberData] = useState({
    name: "",
    pincode: "",
    email: "",
    mobile: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    pincode: "",
  });

  useEffect(() => {
    if (agent === null) {
      window.localStorage.clear();
      navigation({
        pathname: "/",
        search: `?dist=${dUid}`,
        logout: true,
      });
    }

    getAgentDetails(dUid, aUid, (res) => {
      setMemberData({
        name: res.name,
        email: res.email,
        mobile: res.mobile,
        pincode: res.pincode,
      });
      const pincodeValidationError = Validation.validatePincode(res.pincode);
      if (pincodeValidationError === "") {
        setIsPincodeVerified(true);
      } else {
        setIsPincodeVerified(false);
      }
      setLoading(false);
    });
  }, []);

  const fetchPincodeData = (pincode) => {
    setIsPincodeVerified(false);
    setIsLoadingPincode(true);
    const name = "pincode";
    checkPincode(pincode, (res) => {
      if (res.valid) {
        setFormErrors({ ...formErrors, [name]: "" });
        setIsLoadingPincode(false);
        setIsPincodeVerified(true);
      } else {
        setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        setIsLoadingPincode(false);
      }
    });
  };

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    setMemberData({ ...memberData, [name]: value });

    if (/^\d{6}$/.test(value)) {
      fetchPincodeData(value);
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const updateMemberInfo = () => {
    setIsFormSubmitted(true);
    const nameValidationError = Validation.validateFullName(memberData.name);
    const emailValidationError = Validation.validateEmail(memberData.email);
    var pincodeValidationError = Validation.validatePincode(memberData.pincode);

    if (formErrors.pincode === States.INVALID_PINCODE) {
      pincodeValidationError = States.INVALID_PINCODE;
    }

    setFormErrors({
      name: nameValidationError,
      email: emailValidationError,
      pincode: pincodeValidationError,
    });

    if (
      !nameValidationError &&
      !emailValidationError &&
      !pincodeValidationError
    ) {
      const updatedUserInfo = {
        name: memberData.name.trim(),
        email: memberData.email.trim(),
        pincode: memberData.pincode,
      };
      updateAgentDetails(dUid, agent.uid, updatedUserInfo, (val) => {
        if (val === "success") {
          storeAgent(agent.uid, memberData);
          setShowmodal(true);
        }
      });
    }
  };

  return (
    <>
      {showModal && <MyModal dUid={dUid} />}
      {loading ? (
        <Spinner />
      ) : (
        <div className="mainbox-update-user">
          <div className="main-container-user">
            <div className="cover-image-one">
              <img src={stock} alt="" />
            </div>
            <div className="new-user-card-update">
              <div className="new-user-card-one">
                <div className="signUp">
                  <p>Edit Details</p>
                </div>
                <div className="input-container-one">
                  <div className="input-Box">
                    <input
                      type="text"
                      id="fullname"
                      required="required"
                      placeholder="Enter your name"
                      maxLength="25"
                      name="name"
                      value={memberData.name}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          name: e.target.value.slice(0, 25),
                        })
                      }
                    />
                    <i></i>
                  </div>
                  {isFormSubmitted && formErrors.name && (
                    <div className="error-message1" id="namingerror">
                      <span className="error-star">*</span> {formErrors.name}
                    </div>
                  )}
                </div>
                <div className="input-container-two">
                  <div className="input-Box">
                    <input
                      type="text"
                      id="email"
                      required="required"
                      name="email"
                      placeholder="Enter your email"
                      value={memberData.email}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          email: e.target.value,
                        })
                      }
                    />

                    <i></i>
                  </div>

                  {isFormSubmitted && formErrors.email && (
                    <div className="error-message1">
                      <span className="error-star">*</span> {formErrors.email}
                    </div>
                  )}
                </div>

                <div className="input-container-two">
                  <div className="input-Box">
                    <input
                      type="tel"
                      id="email"
                      required="required"
                      disabled={true}
                      value={memberData.mobile}
                    />
                    <i></i>
                  </div>
                </div>
                <div className="input-container-three">
                  <div className="input-Box">
                    <input
                      type="tel"
                      required="required"
                      placeholder="Enter your pincode"
                      id="pincode"
                      name="pincode"
                      value={memberData.pincode}
                      maxLength="6"
                      onChange={handlePincodeChange}
                    />
                    <i></i>

                    {isLoadingPincode && (
                      <div className="pincode-spinner">
                        <Loader />
                      </div>
                    )}
                  </div>
                  {isFormSubmitted && formErrors.pincode && (
                    <div className="error-message1">
                      <span className="error-star">*</span>
                      {formErrors.pincode}
                    </div>
                  )}
                  {!isFormSubmitted && formErrors.pincode && (
                    <div className="error-message1">
                      <span className="error-star">*</span>
                      {formErrors.pincode}
                    </div>
                  )}
                </div>
                <div className="Button-us">
                  <button
                    type="submit"
                    className="btn btn-primary my-2 getotpbtnId"
                    id="getotpbtnId"
                    disabled={!isPincodeVerified}
                    onClick={updateMemberInfo}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
