import React from "react";
import { Link } from "react-router-dom";
import "../css/Footer.css";
import { getFooterImages, importAll } from "./Helper";
import {
  clearStoredAgent,
  getStoredAgent,
} from "./LocalStorage";
import { LOGOUT } from "./States";

function Footer(props) {
  const agent = getStoredAgent();
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  let footerImages = getFooterImages(props.currComp);
  let showUpdateSection = props.currComp === "Profile" ? true : false;

  function setClickedComponent(val) {
    props.setComponent(val);
  }

  return (
    <div className="d-flex flex-row footer-container">
      <div className="item" onClick={() => setClickedComponent("Home")}>
        <img
          src={images[footerImages.home]}
          alt="home"
          values="Home"
          className="footer-image"
        />
        <p className={props.currComp === "Home" ? "active-icon" : "itemName"}>
          Home
        </p>
      </div>

      <div className="item" onClick={() => setClickedComponent("Leads")}>
        <img
          src={images[footerImages.leads]}
          alt="leads"
          values="Leads"
          className="footer-image"
        />
        <p className={props.currComp === "Leads" ? "active-icon" : "itemName"}>
          Leads
        </p>
      </div>

      <div className="item" onClick={() => setClickedComponent("Profile")}>
        <img
          src={images[footerImages.profile]}
          alt="profile"
          values="Leads"
          className="footer-image"
        />
        <p
          className={props.currComp === "Profile" ? "active-icon" : "itemName"}
        >
          Profile
        </p>
      </div>

      <div className={`update-section ${showUpdateSection ? "show" : ""}`}>
        <Link
          to={{
            pathname: "/UpdateProfile",
            search: `?agentId=${agent.uid}&dist=${props.owner}`,
          }}
          className="linkClass-update"
        >
          <div className="update-section-main">
            <div className="update-section-one update-data">
              <img src={images["Profile.svg"]} alt="" className="edit-image" />
            </div>
            Edit Profile
          </div>
        </Link>

        <div className="update-section-main" onClick={logOut}>
          <div className="update-section-one">
            <img src={images["Signout.svg"]} alt="" className="edit-image" />
          </div>
          <div
            className="update-section-two logout"
            style={{ borderBottom: "none" }}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );

  function logOut() {
    clearStoredAgent();
    props.setAgentState(LOGOUT);
  }
}

export default Footer;
