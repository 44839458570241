import React, { useState, useEffect } from "react";
import "./App.css";
import { getOwnerDetails, getAgentDetails } from "./Firebase";
import { useSearchParams } from "react-router-dom";
import Login from "./Components/Login";
import NewUser from "./Components/NewUser";
import Home from "./Components/Home";
import InvalidUrl from "./Components/InvalidUrl";
import VerifyLoginOtp from "./Components/VerifyLoginOtp";
import * as States from "./Components/States.js";
import {
  storeAgent,
  getStoredAgent,
  getStoredOwner,
  storeOwner,
} from "./Components/LocalStorage";
import Spinner from "./Components/Spinner.js";

function App() {
  const [searchParams] = useSearchParams();
  const dUid = searchParams.get("dist");
  const storedAgent = getStoredAgent();
  const storedOwner = getStoredOwner();

  const [currentAgent, setCurrentAgent] = useState({});
  const [states, setStates] = useState({
    agentState: States.INIT,
    ownerState: States.INIT,
    isNewUser: true
  });
 
  const [showSpinner, setShowSpinner] = useState(false);

  function setAgentState(state) {
    // console.log("setAgentState ", state)
    if (state === States.LOGOUT) {
      setStates({ agentState: state, ownerState: States.VALID, isNewUser:false });
    } else {
      setStates({ agentState: state, ownerState: states.ownerState, isNewUser: false });
    }
  }

  useEffect(() => {
    
    if (dUid === null) {
      setStates({ agentState: States.INIT, ownerState: States.INVALID });
    } else if (
      storedOwner !== null &&
      storedOwner.uid === dUid &&
      storedAgent !== null
    ) {
      if (
        storedAgent.name === "" ||
        storedAgent.mobile === "" ||
        storedAgent.email === ""
      ) {
        setStates({ agentState: States.LOGEDIN, ownerState: States.VALID, isNewUser: true });
      } else {
        setStates({ agentState: States.LOGEDIN, ownerState: States.VALID, isNewUser: false });
      }
      
    } else if (
      storedOwner === null ||
      states.ownerState === States.INIT ||
      states.ownerState === States.SWITCHED
    ) {
      getOwnerDetails(dUid, (res) => {
        if (res === States.FAILURE) {
          setStates({
            agentState: states.agentState,
            ownerState: States.INVALID,
            isNewUser: states.isNewUser
          });
        } else {
          storeOwner(dUid, res);
          setStates({
            agentState: states.agentState,
            ownerState: States.VALID,
            isNewUser: states.isNewUser
          });
        }
      });
    }
  }, []);

  function loginCallBack(val) {
    setCurrentAgent({ uid: "", name: "", mobile: val });
    setStates({ agentState: States.LOGIN_INIT, ownerState: states.ownerState, isNewUser: states.isNewUser });
  }

  function setAgent(agent) {
   
    setShowSpinner(true);
    let isUserNew = true;
    getAgentDetails(dUid, agent.uid, (res) => {
      if (
        res.name === undefined ||
        res.mobile === undefined ||
        res.email === undefined ||
        res.pincode === undefined
      ) {
        storeAgent(agent.uid, {
          name: res.name !== undefined ? res.name : "",
          mobile: currentAgent.mobile,
          email: "",
          pincode: "",
        });

        isUserNew = true;
        setCurrentAgent(
          (currentAgent) =>
            (currentAgent = {
              uid: agent.uid,
              name: res.name !== undefined ? res.name : "",
              mobile: currentAgent.mobile,
              email: "",
              pincode: "",
            })
        );
        
      } else {
        storeAgent(agent.uid, {
          name: res.name,
          mobile: currentAgent.mobile,
          email: res.email,
          pincode: res.pincode,
        });
        setCurrentAgent(
          (currentAgent) =>
            (currentAgent = {
              uid: agent.uid,
              name: res.name,
              mobile: res.mobile,
              email: res.email,
              pincode: res.pincode,
            })
        );

        isUserNew = false;
      }

      setStates({ agentState: States.LOGEDIN, ownerState: states.ownerState, isNewUser: isUserNew });
      setShowSpinner(false);
      
    });
  }

  function isNewUserAdded(isAdded) {
    if (isAdded) {
      setStates({ agentState: states.agentState, ownerState: states.ownerState, isNewUser: false });
    }
  }

  // console.log("agentState  ", states," ", showSpinner)
  if (showSpinner) {
    return (
      <>
        <Spinner />
      </>
    );
  } else if (states.ownerState === States.INVALID) {
    return (
      <div>
        <InvalidUrl />
      </div>
    );
  } else if (storedOwner != null && storedOwner.uid !== dUid) {
    localStorage.clear();
    setStates({ agentState: States.INIT, ownerState: States.SWITCHED, isNewUser: true });
  } else if (
    storedAgent !== null &&
    storedOwner !== null &&
    storedOwner.uid === dUid &&
    states.ownerState === States.VALID &&
    states.agentState === States.LOGEDIN &&
    !states.isNewUser
  ) {
    return <Home dUid={dUid} setAgentState={setAgentState} />;
  } else if (
    storedOwner !== null &&
    (states.agentState === States.INIT ||
      states.agentState === States.LOGOUT) &&
    states.ownerState === States.VALID
  ) {
    return <Login getLoginCallBack={loginCallBack} />;
  } else if (
    storedOwner !== null &&
    states.agentState === States.LOGEDIN &&
    states.ownerState === States.VALID &&
    states.isNewUser
  ) {
    return (
      <div>
        <NewUser agent={storedAgent} isNewUserAdded={isNewUserAdded} />
      </div>
    );
  } else if (
    states.agentState === States.LOGIN_INIT &&
    states.ownerState === States.VALID
  ) {
    if (showSpinner) {
      return (
        <>
          <Spinner />
        </>
      );
    } else {
  
      return (
        <VerifyLoginOtp
          mobile={currentAgent.mobile}
          getAgent={setAgent}
        />
      );
    }
  }
  return <div></div>;
}

export default App;
