import React, { useState } from "react";
import * as States from "./States";
import Loader from "./Loader";
import * as Validation from "./Validations";
import { submitAgentProfile } from "../Firebase";
import { checkPincode } from '../Connection';
import { importAll } from "./Helper";
import "../css/Login.css";
import "../css/NewUser.css";
import { getStoredOwner, storeAgent } from "./LocalStorage";

export default function NewUser(props) {


  const owner = getStoredOwner();

  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  const [isLoadingPincode, setIsLoadingPincode] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isPincodeVerified, setIsPincodeVerified] = useState(false);

  const memberData = {
    name: props.agent.name,
    email: "",
    mobile: props.agent.mobile,
    pincode: "",
  };
  const [memberDetails, setMemberDetails] = useState(memberData);

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    pincode: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setMemberDetails({ ...memberDetails, [name]: value });

    if (value !== "") {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const fetchPincodeData = (pincode) => {
    setIsPincodeVerified(false);
    setIsLoadingPincode(true);
    const name = "pincode";
    checkPincode(pincode , (res) => {
      if(res.valid){
        setFormErrors({ ...formErrors, [name]: "" });
        setIsLoadingPincode(false);
        setIsPincodeVerified(true);
      }
      else{
        setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        setIsLoadingPincode(false);
      }
    })
  };

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    setMemberDetails({ ...memberDetails, [name]: value });

    if (/^\d{6}$/.test(value)) {
      fetchPincodeData(value);
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleContinueClick = () => {
    setIsFormSubmitted(true);
    const fullNameValidation = Validation.validateFullName(memberDetails.name);
    const emailValidation = Validation.validateEmail(memberDetails.email);
    var pincodeValidation = Validation.validatePincode(memberDetails.pincode);

    if (formErrors.pincode === States.INVALID_PINCODE) {
      pincodeValidation = States.INVALID_PINCODE;
    }

    setFormErrors({
      name: fullNameValidation,
      email: emailValidation,
      pincode: pincodeValidation,
    });

    if (!fullNameValidation && !emailValidation && !pincodeValidation) {
      submitAgentProfile(owner.uid, props.agent.uid, memberDetails, (val) => {

        storeAgent(props.agent.uid, {
          name: memberDetails.name,
          mobile: props.agent.mobile,
        });
        props.isNewUserAdded(val);
      });
    }
  };

  return (
    <>
      <div className="newuser-page">
        <div className="desktop-newuser">
          <div className="ownerDetails">
            <p>Business Owner</p>
            <p>{owner.name}</p>
            <p>{owner.mobile}</p>
          </div>
        </div>
        <div className="mobile-newuser">
          <div className="ownerDetails">
            <p>Business Owner</p>
            <p>{owner.name}</p>
            <p>{owner.mobile}</p>
          </div>
        </div>
        <div className="mainbox-new-user">
          <div className="main-container-user">
            <div className="cover-image-one">
              <img src={images["signup.png"]} alt="" />
            </div>
            <div className="new-user-card">
              <div className="new-user-card-one">
                <div className="signUp">
                  <p>Sign Up</p>
                </div>
                <div className="input-container-one">
                  <div className="input-Box">
                    <input
                      type="text"
                      id="fullname"
                      placeholder="Enter your Name"
                      required="required"
                      maxLength="25"
                      name="name"
                      value={memberDetails.name}
                      onChange={onInputChange}
                    />
                    <i></i>
                  </div>
                  {isFormSubmitted && formErrors.name && (
                    <div className="error-message1" id="namingerror">
                      <span className="error-star">*</span> {formErrors.name}
                    </div>
                  )}
                </div>
                <div className="input-container-two">
                  <div className="input-Box">
                    <input
                      type="text"
                      id="email"
                      required="required"
                      placeholder="Enter your Email"
                      name="email"
                      value={memberDetails.email}
                      onChange={onInputChange}
                    />
                    <i></i>
                  </div>
                  {isFormSubmitted && formErrors.email && (
                    <div className="error-message1">
                      <span className="error-star">*</span> {formErrors.email}
                    </div>
                  )}
                </div>
                <div className="input-container-two">
                  <div className="input-Box">
                    <input
                      type="tel"
                      id="tel"
                      required="required"
                      name="mobile"
                      value={props.agent.mobile}
                      disabled={true}
                    />
                    <i></i>
                  </div>
                </div>
                <div className="input-container-three">
                  <div className="input-Box">
                    <input
                      type="tel"
                      required="required"
                      id="pincode"
                      name="pincode"
                      placeholder="Enter your Pincode"
                      value={memberDetails.pincode}
                      maxLength="6"
                      onChange={handlePincodeChange}
                    />
                    <i></i>
                    {isLoadingPincode && (
                      <div className="pincode-spinner">
                        <Loader />
                      </div>
                    )}
                  </div>
                  {isFormSubmitted && formErrors.pincode && (
                    <div className="error-message1">
                      <span className="error-star">*</span>
                      {formErrors.pincode}
                    </div>
                  )}
                  {!isFormSubmitted && formErrors.pincode && (
                    <div className="error-message1">
                      <span className="error-star">*</span>
                      {formErrors.pincode}
                    </div>
                  )}
                </div>
                <div className="Button-us">
                  <button
                    type="submit"
                    className="btn btn-primary my-2 submitnewuser"
                    id="submitnewuser"
                    onClick={handleContinueClick}
                    disabled={!isPincodeVerified}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="quickLinks-q-new">
          <div className="tagline">
            <p>
              Our <span>Product Portfolio</span>
            </p>
          </div>
          <div className="boxes">
            <div className="box1">
              <img src={images["demat_new.png"]} alt="" />
            </div>
            <div className="box2">
              <img src={images["savings_acc_new.png"]} alt="" />
            </div>
            <div className="box3">
              <img src={images["cc_new.png"]} alt="" />
            </div>
            <div className="box4">
              <img src={images["loan_new.png"]} alt="" />
            </div>
            <div className="box5">
              <img src={images["bnpl_new.png"]} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
