import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../css/AllProjects.css";

function AllProjectsShimmer() {
  return (
    <>
    <div
      className="main-container"
      style={{ backgroundColor: "white", marginBottom: "-52px" }}
    >
      <SkeletonTheme baseColor="#e6e7e9" highlightColor="#ffffff">
        <div className="listProjects">
          <div className="linkPath">
            <div className="mainbox-page">
              <div style={{ width: "100%", height: "180px" }}>
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
    <div
      className="main-container"
      style={{ backgroundColor: "white", marginBottom: "-52px" }}
    >
      <SkeletonTheme baseColor="#e6e7e9" highlightColor="#ffffff">
        <div className="listProjects">
          <div className="linkPath">
            <div className="mainbox-page">
              <div style={{ width: "100%", height: "180px" }}>
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
    <div
      className="main-container"
      style={{ backgroundColor: "white", marginBottom: "-52px" }}
    >
      <SkeletonTheme baseColor="#e6e7e9" highlightColor="#ffffff">
        <div className="listProjects">
          <div className="linkPath">
            <div className="mainbox-page">
              <div style={{ width: "100%", height: "180px" }}>
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
    <div
      className="main-container"
      style={{ backgroundColor: "white", marginBottom: "-52px" }}
    >
      <SkeletonTheme baseColor="#e6e7e9" highlightColor="#ffffff">
        <div className="listProjects">
          <div className="linkPath">
            <div className="mainbox-page">
              <div style={{ width: "100%", height: "180px" }}>
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
    <div
      className="main-container"
      style={{ backgroundColor: "white", marginBottom: "-52px" }}
    >
      <SkeletonTheme baseColor="#e6e7e9" highlightColor="#ffffff">
        <div className="listProjects">
          <div className="linkPath">
            <div className="mainbox-page">
              <div style={{ width: "100%", height: "180px" }}>
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
    </>
  );
}

export default AllProjectsShimmer;
