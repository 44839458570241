import React, { useEffect, useState } from "react";
import { Linking } from "react-native";

function DetailsPS(props) {
  const [activeSection, setActiveSection] = useState("guide");
  const [activeButton, setActiveButton] = useState("guide");

  const guidelines = props.guide;
  const [guidelinesArray, setGuidlinesArray] = useState([]);

  const dosNdonts = props.dosNdonts;
  const [dosNdontsArray, setDosNDontsArray] = useState([]);

  const eligibility = props.eligibility;
  const [eligibilityArray, setEligibilityArray] = useState([]);

  const documents = props.documents;
  const [documentsArray, setDocumentsArray] = useState([]);

  const projectDetails = props.projectDetails;
  const [projectDetailsArray, setProjectDetailsArray] = useState([]);

  useEffect(() => {
    if (guidelines) {
      const guidelines_list = guidelines.split(/\\n\\n/).filter(Boolean);
      setGuidlinesArray(guidelines_list);
    }

    if (dosNdonts) {
      const dosNdonts_list = dosNdonts.split(/\\n\\n/).filter(Boolean);
      setDosNDontsArray(dosNdonts_list);
    }

    if (eligibility) {
      const eligibility_list = eligibility.split(/\\n\\n/).filter(Boolean);
      setEligibilityArray(eligibility_list);
    }

    if (documents) {
      const documents_list = documents.split(/\\n\\n/).filter(Boolean);
      setDocumentsArray(documents_list);
    }

    if (projectDetails) {
      const projectDetails_list = projectDetails.split(/\\n\\n/).filter(Boolean);
      setProjectDetailsArray(projectDetails_list);
    }
  }, [guidelines ,dosNdonts, eligibility , documents ,  projectDetails]);

  const shareOnWatsApp = async (prod = "") => {

    // const queryParams = `dUid=${props.dUid}&aUid=${props.aUid}`;
    let message = encodeURIComponent(
      "\n\n" +
        props.message +
        "\n\n" +
        props.projectData.link +
        "&d=" + props.dUid + "&m=" + props.aUid
        
    );
    let url = "whatsapp://send?text=" + message;
    await Linking.openURL(url);
  };

  return (
    <>
      <div className="details-container-ps">
        <div className="Buttons-ps">
          <button
            type="button"
            className={activeButton === "guide" ? "active-button" : ""}
            onClick={() => {
              setActiveSection("guide");
              setActiveButton("guide");
            }}
          >
            GUIDE
          </button>
          <button
            type="button"
            className={activeButton === "eligibility" ? "active-button" : ""}
            onClick={() => {
              setActiveSection("eligibility");
              setActiveButton("eligibility");
            }}
          >
            ELIGIBILITY & DOCUMENTS
          </button>
          <button
            type="button"
            className={activeButton === "details" ? "active-button" : ""}
            onClick={() => {
              setActiveSection("details");
              setActiveButton("details");
            }}
          >
            PRODUCT DETAILS
          </button>
        </div>
        <div className="information-container-ps">
          <div className="guidelines-container">
            <div
              id="guide-box"
              className={activeSection === "guide" ? "active" : ""}
            >
              <div className="guidelines">
                <p>Step by Step Guide</p>
                <ol className="list-data">
                  {guidelinesArray.map((det, index) => (
                    <li key={index}>
                      <span>{det}</span>
                    </li>
                  ))}
                </ol>
              </div>

              <div className="guidelines">
                <p>Dos And Dont's</p>
                <ol className="list-data">
                  {dosNdontsArray.map((det, index) => (
                    <li key={index}>
                      <span>{det}</span>
                    </li>
                  ))}
                </ol>
              </div>
            </div>

            <div
              id="guide-box"
              className={activeSection === "eligibility" ? "active" : ""}
            >
              <div className="guidelines">
                <p>Eligibility</p>
                <ol className="list-data">
                  {eligibilityArray.map((det, index) => (
                    <li key={index}>
                      <span>{det}</span>
                    </li>
                  ))}
                </ol>
              </div>

              <div className="guidelines">
                <p>Documents</p>
                <ol className="list-data">
                  {documentsArray.map((det, index) => (
                    <li key={index}>
                      <span>{det}</span>
                    </li>
                  ))}
                </ol>
              </div>
            </div>

            <div
              id="guide-box"
              className={activeSection === "details" ? "active" : ""}
            >
              <div className="guidelines">
                <p>Project Details</p>
                <ol className="list-data">
                  {projectDetailsArray.map((det, index) => (
                    <li key={index} style={{listStyle : 'none'}}>
                      <span>{det}</span>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="share">
        <button
          type="submit"
          className="share-button"
          onClick={() => {
            shareOnWatsApp();
          }}
        >
          Share
        </button>
      </div>
    </>
  );
}

export default DetailsPS;