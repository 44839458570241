import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../css/Navbar.css";
import MainHome from "./MainHome";
import AllLeadDetails from "./AllLeadsDetails";
import { getStoredAgent } from "./LocalStorage";

export default function Home(props) {
  const dUid = props.dUid;
  const navigation = useNavigate();

  useEffect(()=>{
    if (dUid === undefined || dUid === null) {
      const prev_dUid = JSON.parse(window.localStorage.getItem('dUid'));
      window.localStorage.clear();
          navigation({
            pathname: "/",
            search: `?dist=${prev_dUid}`,
            logout: true,
          });
    }
  }, [])

  const agent = getStoredAgent();
  const [currComponent, setCurrComponent] = useState(
    agent === null ? null : "Home"
  );

  function setComponent(val) {
    setCurrComponent(val);
  }

  if (currComponent === "Home" || currComponent === "Profile") {
    return (
      <div className="home">
        <Navbar name={agent.name} mobile={agent.mobile} />
        <MainHome aUid={agent.uid} dUid={dUid} setComponent={setComponent} />
        <Footer
          owner={dUid}
          setComponent={setComponent}
          currComp={currComponent}
          setAgentState={props.setAgentState}
        />
      </div>
    );
  } else if (currComponent === "Leads") {
    return (
      <div className="home">
        <Navbar name={agent.name} mobile={agent.mobile} />
        <AllLeadDetails dUid={dUid} />
        <Footer
          owner={dUid}
          setComponent={setComponent}
          currComp={currComponent}
        />
      </div>
    );
  } else return <div></div>;
}
