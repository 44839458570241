import React, { useEffect, useState } from "react";
import { importAll } from "./Helper";
import SnapshotsShimmer from "./SnapshotsShimmer";
import {
  getAryoDistLeads,
  getSubmittedLeadsCount,
  getInprocessLeadsCount,
  getApprovedLeadsCount,
  getRejectedLeadsCount,
} from "../Firebase";
import "../css/Snapshots.css";

export default function Snapshots(props) {
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  var agentData = { total: "", approved: "", inprocess: "", rejected: "" };
  var [data, setData] = useState(agentData);
  var [isDataFetched, setIsDataFetched] = useState(false);
  const [showShimmer, setShowShimmer] = useState(true);

  function setLeadsCLicked() {
    props.setComponent("Leads");
  }
  useEffect(() => {
    if (!isDataFetched) {
      getAryoDistLeads(props.aUid, props.dUid, () => {
        agentData.total = getSubmittedLeadsCount();
        agentData.approved = getApprovedLeadsCount();
        agentData.inprocess = getInprocessLeadsCount();
        agentData.rejected = getRejectedLeadsCount();

        setData(agentData);
        setIsDataFetched(true);
        setShowShimmer(false);
      });
    }
  }, [agentData, props.aUid, props.dUid]);

  if (showShimmer) {
    return (
      <>
        <SnapshotsShimmer />
      </>
    );
  } else {
    return (
      <>
        <div className="snapshots-container">
          <h5 className="snapshot-title">DashBoard</h5>

          <div className="container-one">
            <div className="approved">
              <img
                src={images["approved.png"]}
                alt="approvedLeads"
                className="image"
              />
              <p className="leadsCount"> {data.approved}</p>
              <p className="leadsName">Approved</p>
            </div>

            <div className="inprocess">
              <img
                src={images["inprocess.png"]}
                alt="inprocessLeads"
                className="image"
              />
              <p className="leadsCount"> {data.inprocess}</p>
              <p className="leadsName">In Process</p>
            </div>
          </div>

          <div className="container-two">
            <div className="rejected">
              <img
                src={images["reject.png"]}
                alt="rejectedleads"
                className="image"
              />
              <p className="leadsCount"> {data.rejected}</p>
              <p className="leadsName">Rejected</p>
            </div>

            <div className="viewmore" onClick={setLeadsCLicked}>
              <img
                src={images["view_all.png"]}
                alt="allLeads"
                className="image"
              />
              <p className="leadsCount"> {data.total}</p>
              <p className="leadsName">View All</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
