import React, { useEffect } from "react";
import Category from "./Category";
import Snapshots from "./Snapshots";
import SimpleSlider from "./SimpleSlider";
import { removeStoredFilter } from "./LocalStorage";
import "../css/Navbar.css";

export default function MainHome(props) {
  useEffect(() => {
    removeStoredFilter();
  }, []);
  return (
    <>
      <div className="home-page">
        <SimpleSlider dUid={props.dUid} />
        <Snapshots
          dUid={props.dUid}
          aUid={props.aUid}
          setComponent={props.setComponent}
        />
        <Category dUid={props.dUid} />
      </div>
    </>
  );
}
