
export const SearchContainer = ({ onSearchStart }) => {
  return (
    // <div className="d-flex justify-content-center search-container">
      <form className="search">
        <input
          className=""
          id="searchbar"
          type="search"
          placeholder="Search with customer name or mobile"
          onChange={onSearchStart}
          aria-label="Search"
        ></input>
      </form>
    // </div>
  );
};
