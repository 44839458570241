import React, {  useEffect, useState } from "react";

function PaymentFilter({ handleChangePaymentStatus ,  uniquePaymentStatus, handleSpinner  , renderOnClear , onClearFilter}) {


  const storedPaymentStatus = JSON.parse(localStorage.getItem("selectedPaymentStatus")) || [];
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(storedPaymentStatus);



  useEffect(() => {
    if (renderOnClear) {
      setSelectedPaymentStatus([]);
      onClearFilter(); 
    }
  }, [renderOnClear]);

  const handleCheckboxChange = (paymentStatus) => {
    handleSpinner();
    const updateSelection = [...selectedPaymentStatus];

    if (updateSelection.includes(paymentStatus)) {
      updateSelection.splice(updateSelection.indexOf(paymentStatus), 1);
    } else {
      updateSelection.push(paymentStatus);
    }

    setSelectedPaymentStatus(updateSelection);
    localStorage.setItem(
      "selectedPaymentStatus",
      JSON.stringify(updateSelection)
    );
      handleChangePaymentStatus(updateSelection);
    
  };

  return (
    <div className="f-wrapper">
      <p className="f-heading">Payout Status</p>
      <div className="f-data">
        {uniquePaymentStatus.map((paymentstatus, index) => (
          <div key={index}>
            <input
              type="checkbox"
              id={`paymentStatus${index}`}
              value={paymentstatus}
              checked={selectedPaymentStatus.includes(paymentstatus)}
              onChange={() => handleCheckboxChange(paymentstatus)}
            />
            <label htmlFor={`paymentStatus${index}`} id="state_name">{paymentstatus}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PaymentFilter;
