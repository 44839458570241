import React from 'react'
import { importAll } from './Components/Helper'
import './Error.css'
function Error(props) {

  const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
    return (    
    <div className='container'>
      <img src={images['exclamation_red.png']} alt="" className="errorimage" />
      <h6>Opps! Somwthing went wrong</h6>
      <h2>{props.error}</h2>
    </div>
    );
  }

  export default Error;