import { getDistributorId, getUid } from "../Firebase";


var agentName = "";
var agentMobile = "";

export const getAgentName = () => {
    return agentName;
}

export const getAgentMobile = () => {
    return agentMobile;
}

export const setAgentName = (name) => {
    agentName = name;
}

export const setAgentMobile = (mobile) => {
    agentMobile = mobile;
}

export function getShareLink(link) {  
        return (link + getDistributorId() + getUid() + "=");
}

export function getMonthInString(month) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return monthNames[month];
    }

export function importAll(r) {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item);
        });
        return images;
    }   
    
export function getFooterImages(val) {
    if (val === 'Profile') {
        return ({'home':'home_black.png', 'leads':'leads_black.png', 'profile':'profile_logo.png'});
    } else if (val === 'Leads') {
        return ({'home':'home_black.png', 'leads':'leads_logo.png', 'profile':'profile_black.png'});
    } else {
        return ({'home':'home_logo.png', 'leads':'leads_black.png', 'profile':'profile_black.png'});
    }
}


