import React, { useEffect, useState } from "react";
import { formatName } from "./FormatName";
import show_button from "../images/plus_icon.png";
import close_button from "../images/close_icon.png";
import "../css/filter.css";

function CategoryFilter({
  allSubmittedLeads,
  handleChangeProjects,
  renderOnClear,
  onClearFilter,
  handleSpinner
}) {
  const storedProjects =  JSON.parse(localStorage.getItem("selectedProjects")) || [];
  const storedAllSelects = JSON.parse(localStorage.getItem("allSelects")) || [];
  const [uniqueProjectsByCategory, setUniqueProjectsByCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState(storedProjects);
  const [allSelects, setAllSelects] = useState(storedAllSelects);

  useEffect(() => {
    const projectsByCategory = {};

    allSubmittedLeads.map((lead) => {
      const formattedProjectName = formatName(lead.projName);
      const category = lead.leadCat;
      if (!projectsByCategory[category]) 
      {
        projectsByCategory[category] = new Set();
      }
      projectsByCategory[category].add(formattedProjectName);
    });

    setUniqueProjectsByCategory(projectsByCategory);
  }, [allSubmittedLeads]);

  useEffect(() => {
    if (renderOnClear) {
      setSelectedCategory(null);
      setSelectedProjects([]);
      setAllSelects([]);
      onClearFilter();
    }
  }, [renderOnClear]);

  var allSelectInCategories = [];
  const handleSelectAll = (e) => {
    const category = e.target.value;
    allSelectInCategories = [...allSelects];

    if (category) {
      const allProjects = Array.from(uniqueProjectsByCategory[category]);
      const selectedProjectsInCategory = selectedProjects.filter(
        (item) => item.category === category
      );

      // Check if all projects in the category are already selected
      const allSelected =
        selectedProjectsInCategory.length === allProjects.length &&
        allProjects.every((project) =>
          selectedProjectsInCategory.some(
            (item) => item.project === project && item.category === category
          )
        );

      let updatedSelection = [...selectedProjects];

      if (allSelected) {
      
        updatedSelection = updatedSelection.filter(
          (item) => !(item.category === category)
        );
        allSelectInCategories.splice(allSelectInCategories.indexOf(category), 1);
        allSelectInCategories.splice(allSelectInCategories.indexOf(category), 1);
      } else {
     
        allSelectInCategories.push(category);
        allProjects.forEach((project) => {
          if (
            !selectedProjectsInCategory.some(
              (item) => item.project === project && item.category === category
            )
          ) {
            updatedSelection.push({ project, category });
          }
     
        });
      }
      handleSpinner();
      setAllSelects(allSelectInCategories);
      setSelectedProjects(updatedSelection);
      localStorage.setItem("allSelects", JSON.stringify(allSelectInCategories));
      localStorage.setItem(
        "selectedProjects",
        JSON.stringify(updatedSelection)
      );
      handleChangeProjects(updatedSelection);
    }
  };

  const handleCheckboxChange = (project, category) => {
    const updateSelection = [...selectedProjects];

    const isProjectSelected = updateSelection.some(
      (item) => item.project === project && item.category === category
    );

    if (isProjectSelected) {
      updateSelection.splice(
        updateSelection.findIndex(
          (item) => item.project === project && item.category === category
        ),
        1
      );
      allSelectInCategories.splice(allSelectInCategories.indexOf(category), 1);
    } else {
      updateSelection.push({ project, category });
    }
    handleSpinner();
    setAllSelects(allSelectInCategories);
    setSelectedProjects(updateSelection);
    localStorage.setItem("selectedProjects", JSON.stringify(updateSelection));
    handleChangeProjects(updateSelection);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const isCategorySelected = (category) => {
    const selectedCount = selectedProjects.filter(
      (item) => item.category === category
    ).length;

    return selectedCount > 0;
  };

  return (
    <>
      <div className="f-wrapper">
        <p className="f-heading">Categories</p>
        <div className="f-data">
          {Object.keys(uniqueProjectsByCategory).map((category, index) => (
            <div key={index}>
              <p
                className={`category-name ${
                  category === selectedCategory ? "active" : ""
                } ${isCategorySelected(category) ? "selected" : ""}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}{" "}
                <span className="selected-count">
                  {selectedProjects.filter(
                    (proj) =>
                      uniqueProjectsByCategory[category].has(proj.project) &&
                      proj.category === category
                  ).length > 0
                    ? `(${
                        selectedProjects.filter(
                          (proj) =>
                            uniqueProjectsByCategory[category].has(
                              proj.project
                            ) && proj.category === category
                        ).length
                      })`
                    : ""}
                </span>
                <img
                  src={
                    selectedCategory === category ? close_button : show_button
                  }
                  alt=""
                  id="showhide_img"
                />
              </p>
              <div className="project_list">
                {category === selectedCategory && (
                  <>
                    <div>
                      <input
                        type="checkbox"
                        id={`project_${index}_selectAll`}
                        value={category}
                        checked={allSelects.includes(category)}
                        onChange={handleSelectAll}
                      />
                      <label
                        htmlFor={`project_${index}_selectAll`}
                        id="state_name"
                      >
                        Select All
                      </label>
                    </div>
                    {Array.from(uniqueProjectsByCategory[category]).map(
                      (project, subIndex) => (
                        <div key={subIndex}>
                          <input
                            type="checkbox"
                            id={`project_${index}_${subIndex}`}
                            value={project}
                            checked={selectedProjects.some(
                              (item) =>
                                item.project === project &&
                                item.category === category
                            )}
                            onChange={() =>
                              handleCheckboxChange(project, category)
                            }
                          />
                          <label
                            htmlFor={`project_${index}_${subIndex}`}
                            id="state_name"
                          >
                            {project}
                          </label>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CategoryFilter;
