import React from "react";
import errorImg from "../images/errorcloud.png";
import "../css/InvalidUrl.css";

export default function InvalidUrl() {
  return (
    <div className="invalidUrl">
      <img src={errorImg} alt="" className="errImg" />
      <p className="invalidurlmessage">
        Oops! we could not found this URL Valid..
        <br />
        Please ask you owner to share the link again!
      </p>
      <p></p>
    </div>
  );
}
