export const validateMobileNumber = (mobileNumber) => {
  if (mobileNumber === "") {
    return "*Mobile cannot be empty";
  } else if (mobileNumber.length !== 10) {
    return "*Mobile number must be 10 digits";
  } else {
    return "";
  }
};

export const fullNameRegex = /^[0-9A-Za-z\s]+$/;

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export function validateFullName(inputValue) {
  if (inputValue.trim() === "") {
    return "Name cannot be empty";
  } else if (/^\d/.test(inputValue)) {
    return "Name cannot start with a number";
  } else if (!fullNameRegex.test(inputValue)) {
    return "Name should contain only letters, spaces, and numbers";
  }
  return "";
}

export function validateEmail(inputValue) {
  if (!emailRegex.test(inputValue)) {
    return "Please enter a valid email";
  }
  return "";
}

export function validatePincode(inputValue) {
  if (inputValue === "") {
    return "Pincode cannot be empty";
  }
  if (inputValue.length !== 6) {
    return "Pincode must be 6 digits";
  } else {
    return "";
  }
}
