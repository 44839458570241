import React from "react";
import BannerPSShimmer from "./BannerPSShimmer";
import "../css/ProjectShare.css";

function BannerPS(props) {
  if (!props.banner) {
    return (
      <>
        <BannerPSShimmer />
      </>
    );
  } else {
    return (
      <div className="banner-ps">
        <img src={props.banner} alt="banner" className="image-ps skeleton" />
      </div>
    );
  }
}

export default BannerPS;
