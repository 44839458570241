import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/Modal.css";
import ok from "../images/ok.png";

function MyModal(props) {
  const navigation = useNavigate();
  const gotoHome = () => {

    
    navigation({
      pathname: "/",
      search: `?dist=${props.dUid}`,
    });
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-container">
        <img src={ok} alt="" className="ok" />
        <p>Submitted Successfully</p>
        <button className="okButton" onClick={gotoHome}>
          Ok
        </button>
      </div>
    </div>
  );
}

export default MyModal;
