import React from "react";
import "../css/ProjectShare.css";

function NavbarPS(props) {

  return (
    <>
      <div className="linkPath">
        <div className="navbar-ps">
          <div className="category-ps">
            <p id="cat-name">{props.name}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarPS;
